import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import logo from '../../images/logos/logo-inline.svg';
import ContactDetails from '../ContactDetails';

const StyledFooterBG = styled.footer`
  background: ${(props) => props.theme.secondary};
  /* margin-bottom: 1rem; */
`;
const StyledFooter = styled.div`
  color: #fff;
  padding: 3rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(320px, 100%), 1fr));

  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  h3 {
    color: white;
  }
`;
const StyledSubFooter = styled.footer`
  font-size: 0.8rem;
  background: ${(props) => props.theme.primary};
  color: #fff;
  text-align: center;
  padding: 15px;
`;

const Company = () => (
  <div>
    <img alt="madeupnorth" src={logo} style={{ maxWidth: '200px', marginBottom: '1.5rem' }} />
    <div style={{ maxWidth: '75%' }}>
      <p>Professional cleaning solutions have never been easier.</p>
      <p>Book and tailor your one-off, weekly or fortnightly clean from wherever you are.</p>
    </div>
  </div>
);

const Links = () => (
  <div>
    <h3>Links</h3>
    <p>
      <Link to="/services">Services</Link>
    </p>
    <p>
      <Link to="/locations">Locations</Link>
    </p>
    <p>
      <Link to="/about-us">About Us</Link>
    </p>
    <p>
      <Link to="/blog">Blog</Link>
    </p>
    <p>
      <Link to="/contact">Contact</Link>
    </p>
    <p>
      <Link to="/book-now">Book Now</Link>
    </p>
  </div>
);

const Contact = () => (
  <div>
    <h3>Contact</h3>
    <p>
      Sunco House 5 Carliol Square <br />
      Newcastle upon Tyne
      <br />
      United Kingdom
      <br />
      NE1 6UF
    </p>
    <ContactDetails />
  </div>
);

const Footer = () => (
  <StyledFooterBG>
    <StyledFooter>
      <Company />
      <Contact />
      <Links />
    </StyledFooter>
    <StyledSubFooter>Copyright {new Date().getFullYear()} &copy; Maidupnorth</StyledSubFooter>
  </StyledFooterBG>
);

export default Footer;
