import { createGlobalStyle } from 'styled-components';
import normalize from './normalize';
import bg from '../images/png/topography.png';

// Define what props.theme will look like
export const theme = {
  primary: '#3189c8',
  secondary: '#0f1f38',
  dark100: '#000',
  dark50: '#564F62',
  dark10: '#cacaca',
  light: '#f5f5f5',
  font: "'Montserrat', sans-serif",
};

export const GlobalStyle = createGlobalStyle`
  ${normalize};

  body{
    background: #f1f3f4;
    background-image: url(${bg});
   font-family: ${theme.font};
  }

  h1 {
    color: ${theme.secondary};
    margin-bottom: 1.5rem;
  }
  h2 {
    color: ${theme.secondary};
    margin-bottom: 1rem;
  }

  h3 {
    color: ${theme.primary};
    margin-bottom: 0.75rem;
  }

  p {
    margin-bottom: 1rem;
  }

  a{
    color: ${theme.primary};
    text-decoration:none;
    :hover{
      text-decoration:underline;
    }
  }

  hr{
    border:1px solid ${theme.light};
    margin:2rem 0;
  }

`;
