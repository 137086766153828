import React, { useState } from 'react';
import { Link } from 'gatsby';

import ReactModal from 'react-modal';
import styled from 'styled-components';

import useWindowWidth from '../../../hooks/useWindowWidth';
import Modal from './Modal';
import { StyledButtonLink } from '../../Button';

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink = styled(Link)`
  color: #fff;
  font-weight: bold;
  text-transform: capitalize;
  text-decoration: none;
  margin-right: 2rem;

  &:hover {
    opacity: 0.75;
  }
`;

const MenuButton = styled.button`
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  background: ${(props) => props.theme.primary};
  padding: 1rem;
`;

const Menu = () => {
  const [open, setOpen] = useState(false);
  const width = useWindowWidth();
  return (
    <>
      {width > 1230 ? (
        <StyledNav>
          <StyledLink to="/cleaning-services">Cleaning Services</StyledLink>
          <StyledLink to="/locations">Locations</StyledLink>
          <StyledLink to="/about-us">About Us</StyledLink>
          <StyledLink to="/blog">Blog</StyledLink>
          <StyledLink to="/contact">Contact</StyledLink>
          <StyledButtonLink sm="true" to="/book-now">
            Book Now
          </StyledButtonLink>
        </StyledNav>
      ) : (
        <MenuButton onClick={() => setOpen(true)}>Menu</MenuButton>
      )}
      <ReactModal
        isOpen={open}
        contentLabel="Menu"
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        shouldReturnFocusAfterClose
      >
        <Modal setOpen={setOpen} />
      </ReactModal>
    </>
  );
};

export default Menu;
