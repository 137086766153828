import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

export default [
  {
    id: 1,
    name: 'mailto',
    info: 'info@maidupnorth.co.uk',
    icon: FaEnvelope,
  },
  {
    id: 2,
    name: 'tel',
    info: '07525 295 539',
    icon: FaPhoneAlt,
  },
];
