import React from 'react';
import styled from 'styled-components';

import ContainerFixed from './Layouts/ContainerFixed';

const Section = styled.div`
  background: ${(props) => props.theme.light};
  padding: 5rem 0;
  text-align: center;
`;

const ContainedText = styled.div`
  max-width: 60%;
  margin: 0 auto;

  @media (max-width: 990px) {
    max-width: 90%;
  }
`;

function renderText(containedText, children) {
  return containedText ? (
    <ContainerFixed>
      <ContainedText>{children}</ContainedText>
    </ContainerFixed>
  ) : (
    <ContainerFixed>{children}</ContainerFixed>
  );
}

const StyledSection = ({ heading, contained, children }) => (
  <Section>
    {heading && <h2>{heading}</h2>}

    {renderText(contained, children)}
  </Section>
);

export default StyledSection;
