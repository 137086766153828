import React from 'react';
import styled from 'styled-components';

const StyledBlock = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || '1fr 1fr'};
  grid-template-areas: 'first second';
  grid-gap: 30px;

  ${({ alignCenter }) => alignCenter && 'align-items:center;'}

  @media (max-width: ${({ breakpoint }) => breakpoint || '900px'}  ) {
    display: block;

    div{
      margin-bottom:2rem;
      &:last-child{
        margin-bottom:0;
      }
    }

  }
`;

const Block = ({ children, alignCenter, breakpoint, columns }) => (
  <StyledBlock columns={columns} breakpoint={breakpoint} alignCenter={alignCenter}>
    {children}
  </StyledBlock>
);

export default Block;
