import React from 'react';

import ContactInfo from '../data/contact';

const ContactDetails = () =>
  ContactInfo.map(({ id, name, info }) => (
    <p key={id}>
      <a href={`${name}:${info}`}>{info}</a>
      <br />
    </p>
  ));

export default ContactDetails;
