import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import SiteLogo from '../../../images/logos/logo-inline.svg';

// import useWindowWidth from '../../../hooks/useWindowWidth';

const StyledLogo = styled.img`
  max-width: 225px;
  height: 60px;

  @media (max-width: 375px) {
    max-width: 200px;
  }

  @media (max-width: 320px) {
    max-width: 150px;
  }
`;

const Logo = () => (
  <Link to="/">
    <StyledLogo src={SiteLogo} alt="Bedrock42" />
  </Link>
);

export default Logo;
