import styled from 'styled-components';
import { Link } from 'gatsby';

export const StyledOutlineButtonLink = styled(Link)`
  display: inline-block;
  background-color: white;
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 1rem 2rem;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.primary};
    color: white;
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

export const StyledButtonLink = styled(Link)`
  display: inline-block;
  background-color: ${(props) => props.theme.primary};
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: ${({ sm }) => (sm ? '.75rem' : '1rem 2rem')};
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;

  &:hover {
    background-color: #ffffff;
    color: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;
export const StyledButton = styled.button`
  cursor: pointer;
  display: inline-block;
  background-color: ${(props) => props.theme.primary};
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 1rem 2rem;
  border: 1px solid ${(props) => props.theme.primary};
  border-radius: 5px;

  &:hover {
    background-color: #ffffff;
    color: ${(props) => props.theme.primary};
    border: 1px solid ${(props) => props.theme.primary};
  }
`;
