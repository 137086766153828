import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { StyledButton } from '../Button';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
`;
const Inner = styled.div`
  margin: 2rem;
  max-width: 980px;
`;

const ConsentModal = ({ setCookie }) => (
  <Container>
    <Inner>
      <h1>Cookie Policy</h1>
      <div>
        <p>
          We use cookies and other tracking technologies to improve your browsing experience on our
          site, show personalized content and targeted ads, analyze site traffic, and understand
          where our audience is coming from. To find out more or to opt-out, please read our{' '}
          <Link to="/privacy">Privacy Policy.</Link>
        </p>
        <p>
          By choosing I Accept, you consent to our use of cookies and other tracking technologies.
        </p>
      </div>
      <StyledButton onClick={() => setCookie('consent', true)}>I ACCEPT</StyledButton>
    </Inner>
  </Container>
);

ConsentModal.propTypes = {
  setCookie: PropTypes.func.isRequired,
};

export default ConsentModal;
