import React from 'react';
import styled from 'styled-components';

const StyledContainerFixed = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export default function ContainerFixed({ children }) {
  return <StyledContainerFixed>{children}</StyledContainerFixed>;
}
