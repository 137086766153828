import React from 'react';

import { FaUserAlt } from 'react-icons/fa';

import styled from 'styled-components';

import ContactDetails from '../../../data/contact';

const Info = styled.div`
  background-color: ${({ theme }) => theme.primary};
  padding: 0.5rem 2rem;

  ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;

    @media (max-width: 700px) {
      justify-content: space-between;
    }

    li {
      font-size: 90%;
      margin-left: 1.5rem;

      @media (max-width: 700px) {
        margin-left: 0;
        font-size: 80%;
      }

      a {
        display: flex;
        align-items: center;
        color: white;
        font-weight: 700;
        svg {
          color: ${({ theme }) => theme.secondary};
          margin-right: 0.5rem;
        }
      }
    }
  }
`;

const InfoBar = () => (
  <Info>
    <ul>
      <li>
        <a href="https://login.launch27.com/" target="_blank" rel="noopener noreferrer">
          <FaUserAlt />
          Customer Login
        </a>
      </li>
      {ContactDetails.map(({ id, name, icon, info }) => {
        if (name === 'mailto') return null;
        const Icon = icon;
        return (
          <li key={id}>
            <a href={`${name}:${info}`}>
              <Icon />
              {info}
            </a>
          </li>
        );
      })}
    </ul>
  </Info>
);

export default InfoBar;
