import PropTypes from 'prop-types';
import React from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import styled, { ThemeProvider } from 'styled-components';

import ReactModal from 'react-modal';
import ConsentModal from '../ConsentModal';

import { GlobalStyle, theme } from '../../styles/GlobalStyle';

import Footer from '../Footer';
import Header from '../Header';

import '../../styles/modal.css';

const Wrapper = styled.div`
  max-width: 1650px;
  margin: auto;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
`;

const Inner = ({ children }) => {
  const [cookies, setCookie] = useCookies(['consent']);
  return (
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <Wrapper>
          <Header />
          {children}
          {/* {url !== 'contact' && url !== 'privacy' && url !== '404' ? <Proposal /> : ''} */}
          <Footer />
        </Wrapper>
        <GlobalStyle />
        <ReactModal
          className="custom-modal"
          isOpen={!cookies.consent}
          contentLabel="Cookie Policy"
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          shouldReturnFocusAfterClose
        >
          <ConsentModal setCookie={setCookie} />
        </ReactModal>
      </CookiesProvider>
    </ThemeProvider>
  );
};

Inner.propTypes = {
  children: PropTypes.node,
};

Inner.defaultProps = {
  children: [],
};

export default Inner;
