import styled from 'styled-components';

const Close = styled.img`
  width: 2.5rem;
  justify-self: flex-end;

  &:hover {
    cursor: pointer;
  }
`;

export default Close;
