import React from 'react';

import styled from 'styled-components';
import InfoBar from './InfoBar';
import Logo from './Logo';
import Menu from './Menu';

const StyledHeader = styled.header`
  background: ${(props) => props.theme.secondary};
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
`;

const Header = () => (
  <StyledHeader>
    <InfoBar />
    <Main>
      <Logo />
      <Menu />
    </Main>
  </StyledHeader>
);

export default Header;
