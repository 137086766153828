import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import close from '../../../../images/logos/close.svg';

import ModalClose from './Close';

import ModalLayout from './Layout';

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  a {
    font-size: 1.75rem;
    text-transform: capitalize;
    font-weight: bold;
    border-bottom: 1px dashed;

    @media (max-width: 400px) {
      font-size: 1.5rem;
    }
  }
`;

const Modal = ({ setOpen }) => (
  <ModalLayout>
    <ModalClose src={close} alt="close" onClick={() => setOpen(false)} />
    <Container>
      <Link to="/cleaning-services">Cleaning Services</Link>
      <Link to="/locations">Locations</Link>
      <Link to="/about-us">About Us</Link>
      <Link to="/blog">Blog</Link>
      <Link to="/contact">Contact</Link>
    </Container>
  </ModalLayout>
);

Modal.propTypes = {
  setOpen: PropTypes.func.isRequired,
};

export default Modal;
